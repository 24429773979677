import { RadioGroup } from '@emico-react/input-radio'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'

import RadioCustomButton from '../../components/RadioCustomButton'
import theme from '../../theme'
import { useConfiguratorContext } from '../context/ConfiguratorContext'
import { Info } from '../info/Info'
import { HeadingTwo } from '../ui/Typography'

const ConverterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.x3};
  width: 100%;
`

const StyledRadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const InverterType = () => {
  const { register, control, data, choices } = useConfiguratorContext()

  if (!register || !control || !data || !choices) {
    return null
  }

  const staticInverterTypes = [
    {
      value: 'micro',
      title: t({ message: 'micro' }),
    },
    {
      value: 'string',
      title: t({ message: 'string' }),
    },
    {
      value: 'hybrid',
      title: t({ message: 'hybrid' }),
    },
  ]

  return (
    <ConverterWrapper id="inverter-type">
      <HeadingTwo
        fontFamily="headingProxima"
        marginBottom={theme.spacing.x3}
        color={theme.colors.black}
      >
        <Trans>Choose your inverter type</Trans>
      </HeadingTwo>

      <Info
        label={t({
          message: 'How do I choose the best inverter for my solar panels?',
        })}
        tooltipType="configurator-tooltip-invertermodel"
      />

      <StyledRadioGroup
        label="inverterType"
        showLabel={false}
        defaultValue={choices?.inverterType ?? undefined}
      >
        {staticInverterTypes.map((staticType) => (
          <RadioCustomButton
            register={register}
            key={staticType.value}
            radioGroupName="inverterType"
            value={staticType.value}
            isDisabled={
              !data.inverterTypes.some(
                (invertType) => staticType.value === invertType,
              )
            }
          >
            {capitalizeFirstLetter(staticType.title)}
          </RadioCustomButton>
        ))}
      </StyledRadioGroup>
    </ConverterWrapper>
  )
}
