import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Path, useController, FieldValues, Control } from 'react-hook-form'

import {
  PimcoreObjectCategory,
  PimcoreSpecificationsObjectBrickAttribute,
} from '@emico/graphql-schema-types/src'
import { maxWidth, minWidth } from '@emico/styles'
import { Label } from '@emico/ui'

import { getImage, getPrice, getSpecifications, getSurplusPrice } from './utils'
import { getPriceWithCurrency } from '../../lib/getPriceWithCurrency'
import { useStoreConfig } from '../../lib/storeConfig'
import theme from '../../theme'
import { getTypography } from '../../theme/typography'
import { useConfiguratorContext } from '../context/ConfiguratorContext'
import { showProductModal } from '../modal/Modal'
import { Product } from '../product/product'
import { Loading } from '../skeleton/Loading'
import { HeadingThree, SmallText, Span } from '../ui/Typography'

const CardWrapper = styled.div`
  position: relative;
`

const Card = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['active', 'noMonitoring', 'noStorage'].includes(prop),
})<{
  active: boolean
  noMonitoring?: boolean
  noStorage?: boolean
}>`
  background: none;
  margin: 0 0 ${theme.spacing.x2} 0;
  padding: ${({ noMonitoring, noStorage }) =>
    noMonitoring || noStorage ? `${theme.spacing.x2}` : 0};
  border: ${({ active }) =>
    active
      ? `1px solid ${theme.colors.accentGreen}`
      : `1px solid ${theme.colors.gray}`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  width: 100%;
`

const ContentLeft = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`

const ContentMiddle = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-self: stretch;
  text-align: start;
  padding-top: 3px;
  margin-right: 15px;
`

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: flex-end;
  margin-left: auto;
  align-self: stretch;
  justify-content: space-between;
  padding-top: 3px;
  span {
    white-space: nowrap;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  min-width: 66px;
  width: 66px;
  padding: 4px;
  border: 1px solid ${theme.colors.gray};
  position: relative;
  margin-right: ${theme.spacing.x2};

  @media ${minWidth('lg')} {
    margin-right: 0;
    width: 80px;
    min-width: 80px;
    height: 80px;
  }
`

const ProductCardUsp = styled(Span)`
  width: 100%;
  text-align: left;
  color: ${theme.colors.accentBlue};

  @media ${minWidth('lg')} {
    margin-top: 0;
    margin-bottom: 7px;
  }
`

const Specifications = styled(SmallText)`
  margin-bottom: 20px;

  @media ${maxWidth('md')} {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const DetailsButton = styled.button`
  ${getTypography('bodyProxima', 14, 400)}
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  text-decoration-line: underline;

  @media ${minWidth('lg')} {
    position: absolute;
    bottom: 32px;
    right: 20px;
  }
`

const StyledLabel = styled(Label)`
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 27px;
  padding: 0 4px;
  background-color: ${theme.colors.accentBlue};

  > svg {
    fill: ${theme.colors.accentBlue};
  }
`

const LabelTextSmall = styled.span`
  ${getTypography('bodyGalano', 7, 400)}
  white-space: nowrap;
  line-height: 17px;
  display: block;
`

const LabelText = styled.span`
  ${getTypography('bodyGalano', 10, 700)}
  line-height:4px;
  display: flex;
  text-transform: uppercase;
`

const Text = styled.span`
  ${getTypography('bodyGalano', 14, 600)}
`

const HideMobile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${maxWidth('md')} {
    display: none;
  }
`

const HideDesktop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${minWidth('lg')} {
    display: none;
  }
`

const ContentTopMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${theme.spacing.x2};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: ${theme.spacing.x05};
`

const ContentMiddleMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${theme.spacing.x2};
`

const ContentBottomMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Image = styled.img`
  width: auto;
  max-width: 100%;
  height: 100%;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
`

const getInverterType = (item?: Product) => {
  if (!item) {
    return null
  }
  const spec = item?.specifications?.find(
    (spec) => spec?.field === 'inverterType',
  )

  if (!spec) {
    return null
  }

  if (
    spec.value?.__typename === 'PimcoreSpecificationsObjectBrickAttributeString'
  ) {
    return spec.value.stringValue
  }

  return null
}

interface Props<T extends FieldValues> {
  item?: Product
  active: boolean
  controlName: Path<T>
  control: Control<T>
  usp?: boolean
  noMonitoring?: boolean
  noStorage?: boolean
}

export const CardConverter = <T extends FieldValues>({
  item,
  active,
  controlName,
  control,
  usp,
  noMonitoring,
  noStorage,
}: Props<T>) => {
  const { storeConfig } = useStoreConfig()
  const { field } = useController({
    name: controlName as Path<T>,
    control: control,
  })
  const { choices } = useConfiguratorContext()

  const type = getInverterType(item)

  if (noMonitoring) {
    return (
      <CardWrapper>
        <Card
          active={active}
          onClick={() => field.onChange('noMonitoring')}
          noMonitoring
        >
          <Content>
            <Text>Geen monitoring</Text>
          </Content>
        </Card>
      </CardWrapper>
    )
  }

  if (noStorage) {
    return (
      <CardWrapper>
        <Card
          active={active}
          onClick={() => field.onChange('noStorage')}
          noStorage
        >
          <Content>
            <Text>Geen systeem opslag</Text>
          </Content>
        </Card>
      </CardWrapper>
    )
  }

  if (!item) {
    return null
  }

  const handleProductModal = (id: string) =>
    showProductModal({ open: true, id })

  const { name } = item

  const getMicroInverterPrice2 = (item: Product) => {
    const price = item.price || 0

    if (choices?.inverterModelAmount) {
      return `+ ${getPriceWithCurrency(
        price * choices?.inverterModelAmount,
        storeConfig?.defaultDisplayCurrencyCode,
      )}`
    }

    return `+ ${getPriceWithCurrency(
      price * (choices?.solarPanelAmount ?? 0),
      storeConfig?.defaultDisplayCurrencyCode,
    )}`
  }

  return (
    <CardWrapper>
      <Card active={active} onClick={() => field.onChange(item.id)}>
        <Content>
          <HideDesktop>
            <ContentTopMobile>
              <ImageContainer>
                <Image src={getImage(item)} alt={item.name ?? ''} />

                {item.bestChoice && (
                  <StyledLabel>
                    <Trans>
                      <LabelTextSmall>our best</LabelTextSmall>

                      <LabelText>choice</LabelText>
                    </Trans>
                  </StyledLabel>
                )}
              </ImageContainer>

              <Column>
                <HeadingThree>{name}</HeadingThree>
                {usp && item.usp && <ProductCardUsp>{item.usp}</ProductCardUsp>}
              </Column>
            </ContentTopMobile>

            <ContentMiddleMobile>
              {item.specifications && item.categories && (
                <Specifications color={theme.colors.darkGray}>
                  {getSpecifications(
                    item.specifications as PimcoreSpecificationsObjectBrickAttribute[],
                    item.categories as PimcoreObjectCategory[],
                  )}
                </Specifications>
              )}
            </ContentMiddleMobile>

            <ContentBottomMobile>
              <DetailsButton onClick={() => handleProductModal(item.id ?? '')}>
                <Span isBold>
                  <Trans>Show details</Trans>
                </Span>
              </DetailsButton>

              {item.price ? (
                <Span>
                  {getSurplusPrice(
                    item,
                    storeConfig?.defaultDisplayCurrencyCode,
                  )}
                </Span>
              ) : (
                <Loading height="16px" width="32px" />
              )}
            </ContentBottomMobile>
          </HideDesktop>

          <HideMobile>
            <ContentLeft>
              <ImageContainer>
                <Image src={getImage(item)} alt={item.name ?? ''} />

                {item.bestChoice && (
                  <StyledLabel>
                    <Trans>
                      <LabelTextSmall>our best</LabelTextSmall>

                      <LabelText>choice</LabelText>
                    </Trans>
                  </StyledLabel>
                )}
              </ImageContainer>
            </ContentLeft>

            <ContentMiddle>
              <HeadingThree>{name}</HeadingThree>

              {usp && item.usp && <ProductCardUsp>{item.usp}</ProductCardUsp>}

              {item.specifications && item.categories && (
                <Specifications>
                  {getSpecifications(
                    item.specifications as PimcoreSpecificationsObjectBrickAttribute[],
                    item.categories as PimcoreObjectCategory[],
                  )}
                </Specifications>
              )}
            </ContentMiddle>

            <ContentRight>
              {item.price ? (
                <PriceWrapper>
                  <Span isBold>
                    {type === 'micro'
                      ? getMicroInverterPrice2(item)
                      : getSurplusPrice(
                          item,
                          storeConfig?.defaultDisplayCurrencyCode,
                        )}
                  </Span>

                  {type === 'micro' && (
                    <Span>
                      {getPrice(item, storeConfig?.defaultDisplayCurrencyCode)}
                    </Span>
                  )}
                </PriceWrapper>
              ) : (
                <Loading height="16px" width="32px" />
              )}
            </ContentRight>
          </HideMobile>
        </Content>
      </Card>

      <HideMobile>
        <DetailsButton onClick={() => handleProductModal(item.id ?? '')}>
          <Span withUnderline color={theme.colors.black}>
            <Trans>Show details</Trans>
          </Span>
        </DetailsButton>
      </HideMobile>
    </CardWrapper>
  )
}
