import { makeVar, useReactiveVar } from '@apollo/client'
import { Unmaybe } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { useCallback, useEffect, useState } from 'react'

import { maxWidth } from '@emico/styles'

import { detectCurrentBrowser } from './detectCurrentBrowser'
import { MenuContainer, Animation, LoaderWrapper, ModalContent } from './Modal'
import { ModalHeader } from './ModalHeader'
import { ModalSlideRight } from './ModalSlideRight'
import HtmlContent from '../../components/HtmlContent'
import Loader from '../../components/Loader'
import theme from '../../theme'
import { TooltipTypes } from '../info/Info'
import { CmsBlocksQuery } from '../tooltips/get.generated'

const StyledModalContent = styled(ModalContent)`
  padding: ${theme.spacing.x4};
  @media ${maxWidth('md')} {
    padding: ${theme.spacing.x4} ${theme.spacing.x2};
  }
`

interface ShowTooltipModal {
  open: boolean
  tooltipType: TooltipTypes | undefined
}

export const showTooltipModal = makeVar<ShowTooltipModal>({
  open: false,
  tooltipType: undefined,
})

export type Tooltip = Unmaybe<CmsBlocksQuery, 'cmsBlocks', 'items'>

interface Props {
  tooltips: Tooltip[]
}

export const TooltipModal = ({ tooltips }: Props) => {
  const isSafari = detectCurrentBrowser() === 'Safari'
  const tooltipModalData = useReactiveVar(showTooltipModal)
  const identifier = tooltipModalData.tooltipType
  const show = tooltipModalData.open
  const [tooltip, setTooltip] = useState<Tooltip>()

  useEffect(() => {
    if (tooltips) {
      handleTooltip()
    }
  })

  const handleTooltip = useCallback(() => {
    if (tooltips.length > 0) {
      const tooltip = tooltips.find(
        (tooltip) => tooltip.identifier === identifier,
      )

      return setTooltip(tooltip)
    }

    return undefined
  }, [identifier, tooltips])

  const closeTooltipModal = () => {
    showTooltipModal({ open: false, tooltipType: undefined })
  }

  return (
    <ModalSlideRight open={show} onClose={closeTooltipModal}>
      <Animation>
        <MenuContainer isSafari={isSafari}>
          {!tooltip ? (
            <LoaderWrapper>
              <Loader size="large" />
            </LoaderWrapper>
          ) : (
            <>
              <ModalHeader isSafari={isSafari} close={closeTooltipModal}>
                {tooltip.title ?? ''}
              </ModalHeader>

              <StyledModalContent isSafari={isSafari}>
                <HtmlContent
                  removeSingleParagraphWrapper
                  html={tooltip.content ?? ''}
                />
              </StyledModalContent>
            </>
          )}
        </MenuContainer>
      </Animation>
    </ModalSlideRight>
  )
}
