import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import theme from '../../theme'
import InfoCircleIcon from '../icon/InfoCircleIcon'
import { showTooltipModal } from '../modal/TooltipModal'
import { SmallText, Span } from '../ui/Typography'

const InfoBox = styled('div', {
  shouldForwardProp: (prop) => !['hasTooltip'].includes(prop.toString()),
})<{ hasTooltip?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x3};
  width: 100%;

  &:hover {
    cursor: ${({ hasTooltip }) => hasTooltip && 'pointer'};
  }
`

const StyledIcon = styled(InfoCircleIcon)`
  min-height: 15px;
  min-width: 15px;
  color: ${theme.colors.black};

  @media ${minWidth('lg')} {
    min-height: 18px;
    min-width: 18px;
  }
`

export type TooltipTypes =
  | 'configurator-tooltip-rooforientation'
  | 'configurator-tooltip-invertermodel'
  | 'configurator-tooltip-invertermonitoring'
  | 'configurator-tooltip-inverterstorage'

interface Props {
  label: string
  isSmall?: boolean
  tooltipType?: TooltipTypes
}

export const Info = ({ label, isSmall, tooltipType }: Props) => {
  const handleTooltipModal = (tooltipType?: TooltipTypes) => {
    if (tooltipType) {
      return showTooltipModal({ open: true, tooltipType })
    }

    return
  }

  return (
    <InfoBox
      onClick={() => handleTooltipModal(tooltipType)}
      hasTooltip={Boolean(tooltipType)}
    >
      <StyledIcon />
      {isSmall ? (
        <SmallText color={theme.colors.darkGray}>{label}</SmallText>
      ) : (
        <Span color={theme.colors.darkGray}>{label}</Span>
      )}
    </InfoBox>
  )
}
