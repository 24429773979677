import { useApolloClient } from '@apollo/client'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect } from 'react'

import { getInverterModels } from './get'
import theme from '../../theme'
import { CardConverter } from '../card/CardConverter'
import { useConfiguratorContext } from '../context/ConfiguratorContext'
import { getProductPrices } from '../product/get'
import { Product } from '../product/product'
import { HeadingTwo } from '../ui/Typography'

const InvertModel = styled.div`
  margin-bottom: ${theme.spacing.x3}px;
  gap: ${theme.spacing.x1}px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Wrapper = styled.div`
  padding: ${theme.spacing.x1} 0 ${theme.spacing.x3} 0;
`

const filterOnType = (type: string, inverters: Product[]): Product[] => {
  if (!inverters) {
    return []
  }

  return inverters.filter((inv) => {
    const inverterType = inv.specifications?.find(
      (spec) => spec?.field === 'inverterType',
    )?.value

    if (!inverterType) {
      return false
    }

    const typeString =
      inverterType?.__typename ===
      'PimcoreSpecificationsObjectBrickAttributeString'
        ? inverterType?.stringValue
        : null

    if (!typeString) {
      return false
    }

    return typeString === type
  })
}

interface Props {
  locale: string | undefined
}

export const InverterModel = ({ locale }: Props) => {
  const client = useApolloClient()
  const { control, choices, data, setData } = useConfiguratorContext()

  const refreshConverterModels = useCallback(async () => {
    if (
      !choices?.solarPanel ||
      !data?.inverterModels ||
      !data.solarPanel?.sku
    ) {
      return
    }

    const compatibleInverters = await getInverterModels(
      client,
      locale,
      data.solarPanel.sku,
      choices.solarPanelPositions,
    )

    const compatibleInvertersWithPrices = await getProductPrices(
      client,
      compatibleInverters,
    )

    data.inverterModels = compatibleInvertersWithPrices
  }, [choices?.solarPanel, choices?.solarPanelPositions, client, data, locale])

  useEffect(() => {
    refreshConverterModels()
  }, [choices, refreshConverterModels])

  if (!control || !choices || !data || !setData) {
    return null
  }

  const inverters = data.inverterModels

  if (!choices.inverterType || !inverters || inverters.length === 0) {
    return (
      <Wrapper>
        <Trans>No inverter models found</Trans>
      </Wrapper>
    )
  }

  const noNulls = inverters.filter((inv): inv is Product => inv !== null)

  const filteredByType = filterOnType(choices.inverterType, noNulls)

  return (
    <InvertModel id="inverter-model">
      <HeadingTwo
        fontFamily="headingProxima"
        marginBottom={theme.spacing.x3}
        color={theme.colors.black}
      >
        <Trans>Choose your inverter model</Trans>
      </HeadingTwo>

      {filteredByType.map((inverter) => (
        <CardConverter
          key={inverter.id}
          item={inverter}
          active={choices.inverterModel === inverter.id}
          controlName="inverterModel"
          control={control}
          usp
        />
      ))}
    </InvertModel>
  )
}
