/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useState } from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import RadioCustom from './RadioCustom'
import theme from '../theme'

const RadioWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['isSelected', 'isDisabled'].includes(prop.toString()),
})<{ isSelected: boolean; isDisabled: boolean; hasError?: boolean }>`
  border: ${theme.borderStyles.default};
  border-color: ${({ isSelected }) =>
    isSelected ? theme.colors.green : theme.colors.mediumGray};
  opacity: ${({ isDisabled }) => isDisabled && 0.5};
  font-weight: ${({ isSelected }) => isSelected && theme.fontWeights[700]};
`

const StyledRadioCustom = styled(RadioCustom as any)`
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.x3} ${theme.spacing.x3};
  cursor: ${({ isDisabled }) => isDisabled && 'default'};

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.x3} ${theme.spacing.x5};
  }
`

interface Props<T extends FieldValues>
  extends ComponentProps<typeof RadioCustom> {
  /**
   * Register function from react-hooks-form's `useForm`
   */
  register: UseFormRegister<T>
  /**
   * Radio group name, used for register, all RadioCustomButtons within one group should have the same register name
   */
  radioGroupName: string
  /**
   * Unique radio value
   */
  value: string
  /**
   * Children, used as radio button content
   */
  children: ReactNode
  /**
   * Is the radio button required?
   */
  isRequired?: boolean
}

const RadioCustomButton = <T extends FieldValues>({
  register,
  radioGroupName,
  value,
  children,
  isDisabled = false,
  isRequired = false,
  ...other
}: Props<T>) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  // Get isSelected state from RadioCustom component
  const handleStateChange = (isSelected: boolean) => {
    setIsSelected(isSelected)
  }

  return (
    <RadioWrapper isSelected={isSelected} isDisabled={isDisabled}>
      <StyledRadioCustom
        value={value}
        isSelected={isSelected}
        showButton={false}
        handleCustomRadioState={handleStateChange}
        isDisabled={isDisabled}
        {...register(radioGroupName as Path<T>, {
          required: isRequired,
        })}
        {...other}
      >
        {children}
      </StyledRadioCustom>
    </RadioWrapper>
  )
}

export default RadioCustomButton
