import { SVGProps } from 'react'

const InfoCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <circle
      cx={7.5}
      cy={7.5}
      r={5.627}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 9.688h1.444M7.6 9.688V7.031h-.72M7.563 5.153a.156.156 0 11-.157-.156M7.406 4.997c.087 0 .157.07.157.156"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InfoCircleIcon
